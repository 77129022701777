
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/app';
firebase.initializeApp(firebaseConfig)

import { Observable } from "rxjs";
import { firebaseConfig } from '../utils';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class FirebaseDbService {
    fireDB: any;
    
    constructor() {
        this.fireDB = firebase.database()
    }

    push_data(path: string, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await this.fireDB.ref(path).push(data);
                resolve(resp)
            } catch (error) {
                reject(error)
            }
        })
    }

    save_data(path: string, data: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await this.fireDB.ref(path).set(data);
                resolve(resp)
            } catch (error) {
                reject(error)
            }
        })
    }

    delete_data(path: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await this.fireDB.ref(path).remove();
                resolve(resp)
            } catch (error) {
                reject(error)
            }
        })
    }

    getData(path: string) {
        return new Observable((subscriber: any) => {
            try {
                const ref: any = this.fireDB.ref(path).on('value', (data: any) => {
                    subscriber.next(data.val())
                })
            } catch (error) {
                subscriber.error(error)
                subscriber.complete()
            }
        })
    }
}

