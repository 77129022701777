import { Injectable, inject } from "@angular/core";
import { BusinessService } from "../business/business.service";
import {
  RequestService,
  GlobalsService,
  StorageServices,
  GlobalErrorHandlerService,
  AccountActions,
  IOTP,
  COUNTRIES,
} from "@app/core";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  private api: RequestService;
  protected globalsService: GlobalsService;
  private storageService: StorageServices;
  public businessService: BusinessService;
  private error: GlobalErrorHandlerService;
  readonly defaultCountry = "United Arab Emirates";

  account: any;
  activities: any;
  formProgress!: boolean;
  otpEmail!: string;
  loading!: boolean;
  imgLoading!: boolean;
  access_level: "individual_partner" | "business_partner" | undefined;
  resetPasswordForm!: boolean;

  /** URL of uploaded photo(user profile photo) */
  imageUrl = "";

  /**
   * Cache for user applying as a business partner. This would store the personal information filled
   * on the form before route trigger to business form is activated.
   *
   * Note: This is primarily created for the above purpose.
   */
  cacheUserInfo: { [index: string]: string } | undefined;

  constructor() {
    this.api = inject(RequestService);
    this.globalsService = inject(GlobalsService);
    this.storageService = inject(StorageServices);
    this.businessService = inject(BusinessService);
    this.error = inject(GlobalErrorHandlerService);

    this.storageService.getItem("businessUser").then((cachedUser: any) => {
      this.cacheUserInfo = cachedUser;
    });
  }

  private readonly actions: Record<AccountActions, Function> = {
    login: async (payload: any) => {
      await this.login(payload);
    },
    logout: async () => {
      await this.globalsService.logout();
    },
    register: async (payload: any) => {
      await this.register(payload);
    },
    "create-account": async (payload: any) => {
      await this.action_createAccount(payload);
    },
    "edit-profile": async (payload: any) => {
      await this.editProfile(payload);
      this.globalsService.toast.success("Edited successfully");
    },
    "change-password": async (payload: any) => {
      await this.changePassword(payload);
      this.globalsService.toast.success("Password changed successfully");
    },
    "verify-otp": async (payload: any) => {
      await this.verfiyOTP(payload);
    },
    "delete-account": async () => {
      await this.deleteAccount();
    },
    "get-account": async () => {
      return await this.getUserAccount();
    },
    "get-accounts": async (payload: any) => {},
    "get-profile-img-url": async (payload: any) => {
      await this.getImageURL(payload);
    },
    "save-agreement": async () => {
      await this.saveAgreement();
      this.globalsService.toast.success("Agreement saved");
    },
  };

  public async execute(action: AccountActions, payload?: any) {
    try {
      return await this.actions[action](payload);
    } catch (e: any) {
      this.formProgress = false;
      this.imgLoading = false;
      this.error.handleError(e);
    }
  }

  public async isLoggedOn() {
    return await new Promise(async (resolve, reject) => {
      const config: any = await this.globalsService.storage.getItem("config");
      console.log(config);
      if (config) {
        this.globalsService.config = config || this.globalsService.config;
        this.globalsService.config.country.currency =
          this.globalsService.config.country.currency?.toUpperCase() + " ";
      }

      if (
        !this.globalsService.config ||
        this.globalsService.config.login == false
      )
        reject(false);

      this.account = await this.storageService.getItem("user");
      this.businessService.userBusiness = this.globalsService.config.business;

      if (!this.businessService.userBusiness) reject(false);
      // console.log(!['individual_partner', 'business_partner'].includes(
      //   this.account.access
      // ))
      // console.log(this.account.access)

      if (
        this.account &&
        !["individual_partner", "business_partner"].includes(
          this.account.access
        )
      ) {
        this.error.handleError(
          "Partner level access required. Contact suppport"
        );
        await this.account.logout();
        reject(false);
      }
      resolve(true);
    });
  }

  /****************************************/
  /** Get User Country Config */
  private async getUserCountryConfig(country: string) {
    const resp = (await this.api.get(`config/${country}`)) as {
      message: Array<{ [index: string]: any }>;
    };

    return resp.message[0];
  }

  /****************************************/
  /** Login */
  private async login(data: any) {
    this.formProgress = true;

    const resp = (await this.api.post("login", data)) as {
      access_token: string;
      refresh_token: string;
    };

    this.globalsService.config.jwt = resp.access_token;
    this.globalsService.config.refresh_token = resp.refresh_token;
    this.globalsService.config.login = true;

    await this.getUserAccount();
    await this.globalsService.toastAlert("Logged in Successfully", "success");
    this.country = await this.getUserCountryConfig(
      this.account.current_country
    );
    this.globalsService.storage.saveItem("config", this.globalsService.config);
    await this.globalsService.toastAlert(
      "Retrieved your Partner configuration information",
      "success"
    );

    const businessPref = this.account.preference.filter(
      (pref: any) => pref.type === "current_business"
    )[0];
    await this.businessService.execute("get-business", businessPref);

    this.formProgress = false;

    await this.globalsService.toastAlert(
      "Retrieved your business information",
      "success"
    );
    this.globalsService.navigate("/overview");
  }

  /****************************************/
  /** Verify OTP */
  public async verfiyOTP(data: IOTP) {
    this.formProgress = true;
    await this.api.post("confirm", data);

    this.formProgress = false;
    this.globalsService.navigate("auth/login");
  }

  /****************************************/
  /** Setter => Country */
  private set country(value: { [index: string]: any }) {
    this.globalsService.config.country = value;
    const countryCode = this.getUserCountryCode(value["name"]);
    this.globalsService.config.country_code = countryCode;
  }

  /****************************************/
  /** Setter => Current Country */
  // private set currentCountry(value: string) {
  //   this.globalsService.config.current_country = value;
  // }

  /****************************************/
  /** Get User Country Code */
  public getUserCountryCode(countryName: string | null): string | null {
    if (!countryName) return null;

    const country = COUNTRIES.find((obj) =>
      obj.name.toLowerCase().includes(countryName)
    );

    if (typeof country === "object") return country.code;
    return null;
  }

  /****************************************/
  /** Get Account */
  private async getUserAccount() {
    this.loading = true;
    const resp = (await this.api.get("user")) as {
      message: Array<any>;
    };

    this.account = resp.message[0] as { [index: string]: any };
    this.storageService.saveItem("user", this.account);
    this.loading = false;
    return this.account;
  }

  /****************************************/
  /** Verify account exist */
  private async accountExist(data: { email: string; password: string }) {
    let acctExist;
    try {
      await this.api.post("login", data);
      acctExist = true;
    } catch (e) {
      acctExist = false;
    }
    return acctExist;
  }

  /****************************************/
  /** Registration */
  private async register(data: any) {
    this.formProgress = true;

    if (this.globalsService.title === "agent") {
      /** */
      await this.registerAgent(data);
      /** */
    } else {
      if (this.globalsService.title === "Business") {
        this.formProgress = false;

        // Stores user form data into the cache
        this.cacheUserInfo = data as {
          [index: string]: any;
        };
        this.storageService.saveItem("businessUser", this.cacheUserInfo);

        this.globalsService.navigate("/auth/register/business");
        return;
      }

      await this.registerBusiness(data);
    }

    this.formProgress = false;

    this.otpEmail = data.email;
    this.globalsService.navigate("/auth/otp");
  }

  /*********************************** */
  /** Register as an Agent */
  private async registerAgent(data: any) {
    data.country = this.defaultCountry;
    data.current_country = this.defaultCountry;
    data.access = "individual_partner";

    let loginInfo = {
      email: data["email"],
      password: data["password"],
    };

    const accountExist = await this.accountExist(loginInfo);
    if (accountExist) {
      this.globalsService.toast.error("This user already exist");
      this.globalsService.navigate("/auth/login");
      return;
    }

    const resp = await this.createAccount(data);
    const businessInfo = this.businessService.getAutoFilledBusinessInfo(data);
    businessInfo["business_type"] = "Sole Proprietorships";
    businessInfo["uid"] = resp.uid;
    await this.businessService.execute("create-business", businessInfo);
  }

  /*********************************** */
  /** Register as Business*/
  private async registerBusiness(data: any) {
    // Use the user information stored in cache
    const personalInfo = this.cacheUserInfo as {
      [index: string]: string;
    };

    personalInfo["country"] = this.defaultCountry;
    personalInfo["current_country"] = this.defaultCountry;
    personalInfo["access"] = "business_partner";

    const businessInfo = data;
    businessInfo["country"] = this.defaultCountry;
    businessInfo["email"] = personalInfo["email"];

    let loginInfo = {
      email: personalInfo["email"],
      password: personalInfo["password"],
    };

    const accountExist = await this.accountExist(loginInfo);
    if (accountExist) {
      await this.businessService.execute("create-business", businessInfo);
      return;
    }

    const resp = await this.createAccount(personalInfo);
    businessInfo["uid"] = resp.uid;
    await this.businessService.execute("create-business", businessInfo);
  }

  /****************************************/
  /** Create Account */
  protected async action_createAccount(data: any) {
    this.formProgress = true;
    data.country = this.defaultCountry;
    data.current_country = this.defaultCountry;

    await this.createAccount(data);
    this.formProgress = false;
  }

  private async createAccount(data: any) {
    const resp = (await this.api.post("register", data)) as {
      message: string;
      uid: string;
    };

    return resp;
  }

  async getActivities(businessid: string) {
    const resp = (await this.api.get(`user/activities?uid=${businessid}`)) as {
      message: any;
    };
    this.activities = resp.message;
  }

  /**************************************** */
  /** Edit profile */
  public async editProfile(data: {
    fullname: string;
    email: string;
    username: string;
    image: string;
    sex: string;
  }) {
    const user = await this.globalsService.storage.getItem("user");
    user.fullname = data.fullname;
    user.email = data.email;
    user.username = data.username;
    user.image = data.image;
    user.sex = data.sex;

    return await this.api.update(`user`, user);
  }

  /********************************** */
  /*** Change Password */
  private async changePassword(newPassword: string) {
    const data = {
      uid: this.account?.["uid"],
      password: newPassword,
      email: this.account?.["email"],
    };

    this.formProgress = true;
    this.resetPasswordForm = false;

    const resp = (await this.api.update("changepassword", data)) as {
      message: string;
    };

    this.resetPasswordForm = true;

    await this.getUserAccount();
    this.formProgress = false;
  }

  /********************************** */
  /*** Delete Account */
  private async deleteAccount() {
    await this.api.delete(`remove`);
    this.globalsService.storage.clear();
    this.globalsService.config.login = false;

    this.globalsService.navigate("/auth/login");
  }

  private async getImageURL(data: File) {
    this.imgLoading = true;

    const mediaFile: any = await this.globalsService.uploadFile(data);
    this.imageUrl = mediaFile.url;

    this.imgLoading = false;
  }

  /*************************** */
  /** Save Agreement */
  public async saveAgreement() {
    this.formProgress = true;
    const data = {
      type: "partner_agreement",
      sources: JSON.stringify({
        agreement: "true",
      }),
    };

    await this.api.post(`user/preference`, data);
    await this.getUserAccount();

    this.formProgress = false;
  }

  /**
   * Scans through user preference for each of the provided { types } eg: ['account', 'partner_agreement']
   * returns true if all items in the set { types } exist in the user preference, and false if any item in the set of {types}
   * does not exist in preference.
   * Note: Be sure to provide the right type(s) for the parameter.
   */
  public scanPreference(types: string[]) {
    const preference = this.account.preference as any[];
    let hasTypes = false;
    const existingTypes = preference.map((obj: { type: string }) => obj.type);
    const scanned = types.filter((type: string) =>
      existingTypes.includes(type)
    );

    scanned.length === types.length ? (hasTypes = true) : (hasTypes = false);

    return hasTypes;
  }
}
