export const status = ['pending', 'bidding', 'in-progress', 'completed']
export const request_types = ['']
export const insightCategories: any =  [
  {
      "summary": "food and drinks category of expenses made",
      "name": "food",
      "image": "assets/svg/categories/food.svg",
      "date_added": "2023-06-29 09:49:54.573516",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Gifts category of expenses made",
      "name": "gifts",
      "image": "assets/svg/categories/gift.svg",
      "date_added": "2023-06-29 09:50:35.396282",
      "color": "orange",
      "transactions": 30000,
      "amount": 300000000,
      "category_type": "expenses"
  },
  {
      "summary": "entertainment category of expenses made",
      "name": "entertainment",
      "image": "assets/svg/categories/entertainment.svg",
      "date_added": "2023-06-29 09:50:55.158875",
      "color": "indigo",
      "transactions": 120000,
      "amount": 345600000,
      "category_type": "expenses"
  },
  {
      "summary": "personal category of expenses made",
      "name": "personal",
      "image": "assets/svg/categories/personal.svg",
      "date_added": "2023-06-29 09:51:25.978550",
      "color": "teal",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "insurance category of expenses made",
      "name": "insurance",
      "image": "assets/svg/categories/insurance.svg",
      "date_added": "2023-06-29 09:51:58.342155",
      "color": "white",
      "transactions": 10000,
      "amount": 3450000,
      "category_type": "expenses"
  },
  {
      "summary": "medical category of expenses made",
      "name": "medical",
      "image": "assets/svg/categories/medical.svg",
      "date_added": "2023-06-29 09:52:10.602858",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Transportation category of expenses made",
      "name": "Transportation",
      "image": "assets/svg/categories/transportation.svg",
      "date_added": "2023-06-29 09:52:30.102659",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Housing category of expenses made",
      "name": "Housing",
      "image": "assets/svg/categories/housing.svg",
      "date_added": "2023-06-29 09:52:42.496229",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Miscellaneous category of expenses made",
      "name": "Miscellaneous",
      "image": "assets/svg/categories/miscellaneous.svg",
      "date_added": "2023-06-29 09:53:29.378034",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "children category of expenses made",
      "name": "children",
      "image": "assets/svg/categories/children.svg",
      "date_added": "2023-06-29 09:56:14.698657",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Essential Bills category of expenses made",
      "name": "Essential Bills",
      "image": "assets/svg/categories/bills.svg",
      "date_added": "2023-06-29 09:58:33.935485",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Debt category of expenses made",
      "name": "Debt",
      "image": "assets/svg/categories/debt.svg",
      "date_added": "2023-06-29 09:59:56.406944",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Pet category of expenses made",
      "name": "Pet",
      "image": "assets/svg/categories/pet.svg",
      "date_added": "2023-06-29 10:01:18.184791",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "expenses"
  },
  {
      "summary": "Icome category made",
      "name": "Income",
      "image": "assets/svg/categories/income.svg",
      "date_added": "2023-06-29 10:11:21.944354",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "earnings"
  },
  {
      "summary": "Savings category made",
      "name": "Savings",
      "image": "assets/svg/categories/saving.svg",
      "date_added": "2023-06-29 10:12:34.082740",
      "color": "white",
      "transactions": 1230000,
      "amount": 1300000000,
      "category_type": "earnings"
  },
  {
      "summary": "general expense category",
      "name": "uncategorized",
      "image": "",
      "date_added": "2023-10-02 12:11:51.016791",
      "color": "",
      "transactions": 8730000,
      "amount": 41891470000,
      "category_type": "expenses",
  }
]
export const subscriptionInfo = {
    name: "VK Business Subscription",
    interval: "weekly",
    products: [{
        name: "Credit",
        category: "cards",
    },{
        name: "Auto",
        category: "loans",
    },{
        name: "Personal",
        category: "loans",
    },{
        name: "Home",
        category: "loans",
    },{
        name: "Islamic",
        category: "loans",
    },{
        name: "Health",
        category: "insurance",
    }]
}