export type RequestsAction =
  | 'getRequestInfo'
  | 'getRequests'
  | 'getOffers'
  | 'postOffers'
  | 'onMessage'
  | 'updateStatus';

export enum Status {
  initiated = 'warning',
  approved = 'success',
  completed = 'success',
  inprogress = 'primary',
  bidding = 'info',
  accepted = 'success',
  rejected = 'danger',
  disapproved = 'danger',
}

export type Statuses =
  | 'initiated'
  | 'approved'
  | 'completed'
  | 'inprogress'
  | 'bidding'
  | 'accepted'
  | 'rejected'
  | 'disapproved'
  | 'closed';

export interface IRequestsInsights {
  conversionRate: number;
  totalOffers: number;
  averageOfferPerMonth: number;
  ongoingOffers: number;
  biddingOffers: number;
}

export interface IRequestsOverview {
  totalOffers: number;
  averageOfferAccepted: number;
  averageOfferRejected: number;
  orders: number;
  date: string;
  status: {
    name: Statuses;
    average: number;
    data: number;
  }[];
}

export const MONTHS = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split(
  '_'
);
