import { CanActivateFn } from '@angular/router';
import { CATEGORY_TYPES, subscriptionInfo } from '../utils';
import { GlobalsService } from '../services';
import { inject } from '@angular/core';

export const requestRouteGuard: CanActivateFn = (route, state) => {
  const products = subscriptionInfo.products
  const globals: GlobalsService = inject(GlobalsService);
  const category_name: string = route.params['category'] || globals.pageData.title

  let resp: any
  if(route.params['category']) {
    resp = products.find((category: any) => {
      return category.name.toLowerCase() === category_name.toLowerCase() 
    })
  }
  globals.title = `${category_name} requests`
  const isFound: boolean = (resp || ['offers', 'all'].includes(category_name)) ? true : false;
  return isFound;
};
