import { AbstractControl } from '@angular/forms';

export const emailRegexPattern =
  /^(?:[A-Za-z])+(?:[^+*\\\\/#%&\(\)!\]\[.,=\`"'\?><~|@])*(?:@)(?:[A-Za-z])+(?:[.])(?:[A-Za-z])+$/;

const passwordPatterns = {
  upperCase: /[A-Z]/,
  digit: /[0-9]/,
  symbol: /[$@!><~|%#&*_-]/,
  minLen: /.{8}/,
};

// export const password = (form: AbstractControl) => {
//   const password = form.get('password')?.value;
//   if (!passwordPatterns.upperCase.test(password)) return { uppercase: true };
//   if (!passwordPatterns.digit.test(password)) return { digit: true };
//   if (!passwordPatterns.symbol.test(password)) return { symbol: true };
//   return null;
// };

export const password_len = (form: AbstractControl) => {
  const password = form.get('password')?.value;
  if (!passwordPatterns.minLen.test(password)) return { minLen: true };
  return null;
};

export const password_uppercase = (form: AbstractControl) => {
  const password = form.get('password')?.value;
  if (!passwordPatterns.upperCase.test(password)) return { uppercase: true };
  return null;
};

export const password_digit = (form: AbstractControl) => {
  const password = form.get('password')?.value;
  if (!passwordPatterns.digit.test(password)) return { digit: true };
  return null;
};

export const password_symbol = (form: AbstractControl) => {
  const password = form.get('password')?.value;
  if (!passwordPatterns.symbol.test(password)) return { symbol: true };
  return null;
};

export const confirmPassword = (form: AbstractControl) => {
  const password = form.get('password')?.value;
  const confirmPassword = form.get('confirmPassword')?.value;

  if (password && confirmPassword && confirmPassword !== password) {
    return { mismatch: true };
  }

  return null;
};
