import { Injectable, inject } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalsService } from '../services';


@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
    private globals: GlobalsService
    constructor() {
        this.globals = inject(GlobalsService);
    }

    public intercept(request: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
        const headers: any = {
          'content-type': 'application/json',
          'country': 'united arab emirates',
          'realm': 'default',
        };

        if (this.globals.config) {
          const token = this.globals.config.jwt;
          const country = this.globals.config?.country as { [index: string]: any };
          const country_code = this.globals.config?.country_code;
    
          if (token != null) {
            headers['Authorization'] = `Bearer ${token}`;
            this.checkJwtLife(this.globals.config.jwt as string);
          }
    
          if (country != null) headers['country'] = country['name'].toLowerCase();
          if (country_code != null)
            headers['country_code'] = country_code.toLowerCase();
        }

        const modifiedReq = request.clone({
            setHeaders: headers
        });

        return handler.handle(modifiedReq).pipe(
            map(event => {
                return event;
            }),
            catchError(requestError => {
                if (requestError instanceof HttpErrorResponse)
                  this.errorProcessing(requestError);
                return throwError(
                  (requestError.error?.detail && requestError.error?.detail[0]?.msg) ||
                    requestError.error
                );
            }));
    }

    private errorProcessing(error: any) {
        switch (error.status) {
            case 403:
            case 401:
                this.globals.spinner.text = "Access revoked and denied";
                this.globals.spinner.show();
                setTimeout(async () => {
                    this.globals.spinner.text = "Logging out";
                    this.globals.spinner.hide();
                }, 1000);
                break;
            // case 400:
            //     if (error.error.message) {
            //         this.utilService.showToast(error.error.message);
            //     } else if (error.error.errors && error.error.errors.length > 0) {
            //         error.error.errors.forEach(message => {
            //             this.utilService.showToast(message);
            //         });
            //     }
        }
    }

    private checkJwtLife(jwt: string) {
      if (
        JSON.parse(atob(jwt.split('.')[1])).exp < Math.floor(Date.now() / 1000)
      ) {
        // this.globals.spinner.text = "Access revoked and denied";
        this.globals.spinner.show();
        setTimeout(async () => {
          // this.globals.spinnerText = "Logging out";
          this.globals.spinner.hide();
          // this.globals.loggedOut();
        }, 1000);
      }
    }
}
